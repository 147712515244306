.form-field {
    margin-bottom: 20px;

    &.left {
        
        .title {
            display: flex;
        }
    }

    .generic-input {
        width: 100%;
        padding-bottom: 5px;
        color: $grey;
        border: none;
        border-bottom: 1px solid rgba(204, 204, 204, 1);
        font-size: 200%;
        outline: none;
        background: transparent;
        @include source-sans-pro-regular;

        &.mb {
            margin-bottom: 16px;
        }
        
    }

    .modal-select {
        display: flex;
        flex-direction: column;
        margin:auto;
    }

    .generic-input::-webkit-input-placeholder {
        color: #ccc;
    }

    .generic-input::placeholder {
        color: #ccc;
    }

    .error {
        margin-top: 10px;
        color: $red;
        font-size: 140%;
    }

    .title {
        float: left;
        width: 100%;
        font-size: 200%;
        margin-bottom: 12.5px;
        sub {
            color: $red;
        }

        .small {
            font-size: 90%;
            text-transform: none;
        }
    }

    .input-group {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        position: relative;

        .input-group-prepend {
            display: flex;
            margin-right: -1px;
            border-bottom: 1px solid #ccc;
            padding-bottom: 5px;

            .input-group-text {
                display: flex;
                align-items: center;
                font-size: 200%;
                font-weight: 400;
                text-align: center;
                white-space: nowrap;
            }
        }

        .generic-input {
            position: relative;
            width: 1%;
            flex: 1 1 auto;
            min-width: 0;
            margin-bottom: 0;
        }
        
    }

}

.checkbox-list {

    .checkbox,
    .radio-button {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 16px 32px 16px 0;

        input {
            margin: 0;
            margin-bottom: 8px;
        }

        .holder {
            font-size: 160%;
        }

        &.label-example {
            width: 50%;
        }
    }
    .error {
        left: 0;
        bottom: -5px;
    }
    .full-width {
        width: 100%;
    }
}

//field links
.field-link-container {
    margin-top: 12px;
    text-align: right;
    width: 100%;

    .field-link {
        text-decoration: none;
        font-size: 150%;
        color: $grey;

        &:hover {
            text-decoration: underline;
        }

    }

    &.cvc-code {
        text-align: center;
        
        .field-link {
            font-size: 120%;
            font-style: italic;
            font-weight: 300; 
            pointer-events: none;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

//datepicker field
.date-picker {

    .icon-wrapper {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 160%;
        color: #ccc;
        top: 62%;
        right: 15px;
    }
}

.tab-select-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #e2e2e2;
    border-radius: 50px;
    margin: 50px 0; 
    min-height: 50px;
    padding: 3px;

    .tab-select-item {
        flex: 1;
        padding: 5;
        margin: 2;
        border-radius: 50px;
        padding-top: auto;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: black;
        font-size: 160%;
        transition: background-color 0.2s;
        outline: none;
        border: none;

        &.active {
            background-color: white;
        }
    }
}

.select-list {
    cursor: pointer;

    .selected-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .arrow {
            font-size: 200%;
        }
    }

    .option-selection {
        background-color: rgba(255, 255, 255, 0.6);
        margin-top: 8px;
        transition: 200ms background-color ease-in-out;

        .option-container {

            .option {
                padding: 12px;
                border: 1px solid #ccc;
                border-bottom: 0;

                @include last-child {
                    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                }

                @include hover {
                    background-color: rgba(255, 255, 255, 1);
                }
            }
            .sub-label {
                font-size: 60%;
            }

        }
    }
}

.usage-info {
    font-size: 160%;
    line-height: 1.5;
    margin-bottom: 36px;
}

.autocomplete-dropdown-container {

    .suggestion-item {
        font-size: 160% !important;
        background-color: transparent !important;
        padding: 8px 0;
        transition: background-color 100ms ease-in-out;

        &--active {
            font-size: 160% !important;
            background-color: #ccc !important;
            padding: 8px 0;
            transition: background-color 100ms ease-in-out;
        }
    }
}


.language-switcher {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 35px;
    cursor: pointer;
    color: black;

    &.is-open { 
        background-color: darken($blue, 8%);
        border-radius: 5px; 
    }

    &:hover {
        transition: background-color 0.1s ease-in;
        background-color: darken($blue, 8%);
        border-radius: 5px;
    }

    .language-flag-container {
        display: flex;
        align-items: center;
        border-radius: 6px;
        padding: 0 8px;

        .text {
            font-size: 150%;
            font-weight: 700;
            color: $white;
            margin: 0 10px;
        }

        .language-flag {
            width: 25px;
            height: 15px
        }

        .arrow {
            font-size: 200%;
            color: $white;
            margin-top: 4px;
        }
    }

    .language-select {
        font-size: 160%;

        .placeholder {
            font-weight: 700;
        }

        .arrow {
            margin-left: 8px;
            top: 2px;
        }
        .selected-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
    
            
        }
        
        .option-selection {
            position: absolute;
            background-color: transparent;
            z-index: 999;
            top: 14px;
            right: 0;
            box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
            border: none;
            margin-right: 4px;

            .option-container {
                font-size: 100%;

                .option {
                    display: flex;
                    align-items: center;
                    background-color: #ffff;

                    padding: 10px;

                    &:nth-child(2) {
                        border-top-left-radius: 6px;
                        border-top-right-radius: 6px;
                    }

                    &:last-child {
                        border-bottom-left-radius: 6px;
                        border-bottom-right-radius: 6px;
                    }

                    img {
                        width: 25px;
                        height: 15px;
                        margin-right: 10px;
                    }

                    .sub-label {
                        margin-left: 5px;
                        font-size: 80%;
                    }

                    &:first-child {
                        display: none;
                    }

                    &:hover {
                        // color: black;
                        transition: background-color 0.1s ease-in;
                        background-color: darken(#fafafa, 8%);
                    }

                    &.active {
                        font-weight: 600;
                        font-size: 110%;

                        &:hover {
                            transition: background-color 0.1s ease-in;
                            background-color: darken(#fafafa, 8%);
                        }
                    }
                }
            }
        }
    }
}
