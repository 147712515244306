h1, h2, h3, h4, h5, h6 {
    color: white;
}

.sm-typography {
    color: $light-text !important;
}

.sm-button {

    &.outline {
        border-color: $dark-mode-blue;
        color: $dark-mode-blue;

        &:hover {
            color: white;
        }
    }
}

.sm-login-container {
    background-color: #575B5C;
}


.menu-item-container {
    background-color: $dark-box-background;

    h3, p {
        color: $dark-sub-text !important;
    }

    & .menu-item-title {
        color: white;
    }
}