.transferwise-container {
    width: 100%;

    .transferwise-error {
       
        h1 {
            font-size: 220%;
            font-weight: 600;
            text-align: center;
            margin-bottom: 16px;
        }

        p {
            font-size: 180%;
        }
    }

    .transferwise-body-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .transferwise-logo-wrapper {
            margin: 50px 0 25px;
            display: flex;
            justify-content: center;
        }
        
        .transferwise-logo {
            height: 64px;
            width: 64px;
        }
        
        .subtitle-wrapper {
            font-size: 250%;
            font-weight: 600;
            width: 600px;
            text-align: center;
            line-height: 1.2;     
        }
        
        .info-slug-wrapper {
            margin-bottom: 32px;
            
            & > .info-slug {
                margin-top: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 200%;
                
                & > i {
                    margin-right: 10px;
                }
            }
        }
        .blue {
            color: #00b9ff;
        }
    
        .terms-links-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 180%;
            justify-content: center;
            text-align: center;  
            line-height: 1.2;

    
            & a {
                color: $blue;
            }
            
            & > .button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 24px;
                font-size: 140%;
                color: $blue;
                text-decoration: underline;
            }
    
            & > p:last-child {
                margin-top: 25px;
            }
        }
    }
    
}