.transactions-container {

    .monthly-transaction-container {

        h2 {
            color: $dark-mode-blue !important;
        }

        .transaction-container {

            .transaction-toggle, .content-inner {
                background-color: $dark-box-background;
    
                p {
                    color: $dark-sub-text;
                
                    &.green {
                        color: $confirmButton;
                    }
                }
    
                strong {
                    color: $dark-sub-text !important;
                }
            } 
        }
    }            
}