//colors
$blue: #2E7EC2;
$light-blue: #3FD0FC;
$red: #be3c3d;
$grey: #707171;
$white: #F9FAFB;
$pageBody: #f8f9fa;
$cancelButton: #707272;
$confirmButton: #65bd5b;

//dark mode colours
$pageBodyDark: #33383A;
$dark-box-background: #575B5C;
$dark-sub-text: #f4f4f4;
$light-text: rgba(255, 255,255, 0.9);
$dark-mode-blue: lighten($blue, 10%);

//password-meter
$meter-weak: #F25F5C;
$meter-fair: #FFE066;
$meter-good: #247BA0;
$meter-strong: #70C1B3;

//fonts
@font-face {
    font-family: 'Source Sans Pro';
    src: url("../fonts/SourceSansPro-Regular.ttf");
}
$source-sans-pro: 'Source Sans Pro', sans-serif;

@mixin source-sans-pro-regular() {
    font-family: $source-sans-pro;
    font-weight: 400;
}

@mixin source-sans-pro-semi-bold() {
    font-family: $source-sans-pro;
    font-weight: 600;
}

@mixin source-sans-pro-bold() {
    font-family: $source-sans-pro;
    font-weight: 700;
}

@mixin source-sans-pro-black-italic() {
    font-weight: 900;
    font-style: italic;
}

//centering
@mixin centered-content(){
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin vertical-center(){
    top: 50%;
    transform: translateY(-50%);
}

@mixin horizontal-center(){
    left: 50%;
    transform: translateX(-50%);
}

//rounded corners
@mixin rounded-all($radius: 5px){
    border-radius: $radius;
}
@mixin rounded-top($radius: 5px){
    border-radius: $radius $radius 0 0;
}
@mixin rounded-bottom($radius: 5px){
    border-radius: 0 0 $radius $radius;
}
@mixin rounded-left($radius: 5px){
    border-radius: $radius 0 0 $radius;
}
@mixin rounded-right($radius: 5px){
    border-radius: 0 $radius $radius 0;
}

@mixin last-child {
    &:last-child {
        @content;
    }
}

@mixin contentMarginBottom {
    margin-bottom: 18px;
}

//dimensions
@mixin fullPageDimensions() {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin hover() {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}