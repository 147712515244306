.uk-payee-container {
        
    .uk-payee-item {
        border: 2px rgba(112,114,114,0.38) solid;
        margin-bottom: 28px;
        padding: 16px 64px; 
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 7px;

        &.clickable {
            cursor: pointer;
        }

        .item-left {

            .uk-payee-name {
                font-size: 200%;
            }

            .uk-payee-account {
                display: flex;
                margin-top: 8px;
                font-size: 170%;

                span {
                    margin: 0px 6px; 
                }
            }
        }

        .item-right {
            
            i {
                font-size: 220%;
            }
        }

        .delete-button {
            background-color: transparent;

            i {
                font-size: 200%;
                color: #707171;
            }
        }

    }
}

.international-payee-container {
    text-align: center;
    
    p {
        font-size: 180%;
        margin: 0 auto 32px;
        line-height: 1.4;

        @include devices(xl) {
            width: 960px;
        } 
    }
}

.payee-switch-container {
    display: flex;
    margin: 0 auto 32px;
    width: 50%;

    .switch {
        padding: 16px 16px 3px;
        width: 50%;
        text-align: center;
        border: 2px solid $blue;
        cursor: pointer;
        background-color: $blue;
        color: white;

        &.active {
            background-color: white;
            color: $blue;
        }

        h6 {
            font-size: 175%;
        }

        &:last-child {
            border-left-width: 0px;
        }
    }
}

