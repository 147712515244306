.cards-container {

    .content {
        
        &.new-card{
            background-color: $dark-box-background!important;
            border-color: $dark-sub-text!important;

            i {
                color: $dark-sub-text!important;
            }
            .title {
                color: $dark-sub-text!important;
            }
        }
    }
}