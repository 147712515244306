.sm-page-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @include devices(sm) {
        max-width: 520px;
    }
    
    @include devices(md) {
        max-width: 720px;
    }

    @include devices(lg) {
        max-width: 960px;
    }

    @include devices(xl) {
        max-width: 1024px;
    }

    &.row {
        flex-direction: row;
    }

    &.center {
        justify-content: center;
        align-items: center;
    }

    &.mini {
        max-width: 540px;
        margin: 0 auto;
    }

    &.extraMini {
        max-width: 360px;
        margin: 0 auto;
    }
}

.sm-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &.row {
        flex-direction: row;

        &.v-center {
            justify-content: center;
        }
    }

    &.v-center {
        align-items: center;
    }

    &.center {
        justify-content: center;
        align-items: center;
    }
}

.sm-box {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    &.row {
        flex-direction: row;
    }

    &.center {
        align-items: center;
        justify-content: center;
    }
}

.sm-terms-and-conditions {

    .section {
        margin-bottom: 48px;
    }
}

.sm-grid-container {
    display: grid;
    
    &.full-width {
        width: 100%;
    }

    @media (max-width: 960px) {
        grid-template-columns: 1fr !important;
    }
}

.sm-button-container {
    display: flex;

    .sm-button, button {
        margin-right: 32px;
        text-align: center;

        @include last-child {
            margin-right: 0;
        }
    }

    &.no-display {
        display: none;
    }

    &.center {
        justify-content: center;
    }
}

.sm-button {
    width: 50%;
    margin-top: 24px;
    padding: 17px 15px;
    font-size: 250%;
    @include source-sans-pro-semi-bold;
    background-color: $blue;
    color: white;
    border-radius: 5px;
    line-height: 1;
    text-align: center;
    -webkit-appearance: none;

    &:hover {
        opacity: 0.9;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
            opacity: 0.5;
        }
    }

    .icon {
        margin-right: 16px;
    }

    &.unfreeze {
        background-color: $light-blue;
    }

    &.cancel {
        background-color: $cancelButton;
    }

    &.confirm {
        background-color: $confirmButton;
    }

    &.danger {
        background-color: $red;
    }

    &.outline {
        background-color: transparent;
        border: 2px $blue solid;
        color: $blue;
        transition: 200ms all ease-in-out;

        &:hover {
            background-color: $blue;
            color: white;
        }
    }

    &.fit-content {
        display: block;
        width: auto;
        padding: 12px 72px;
    }

    &.small {
        max-width: 320px;
        font-size: 130%;
    }

    &.medium {
        max-width: 540px;
    }

    &.full {
        width: 100%;
    }

    &.center {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.sm-infinite-scroll {

    &.small {
        max-height: 40vh;
        overflow-y: auto;
    }

    .loading-more {
        font-size: 200%;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sm-login-container {

    .statement-container {
        text-align: center;
    }

    .styles_react-code-input-container__tpiKG {
        width: auto !important;
        margin: 0 auto;
        text-align: center;
        margin-top: 16px;
  
        input {
            border: 2px solid $blue !important;
            border-radius: 8px !important;
            background-color: $pageBody;
            margin-right: 6px;
            font-family: $source-sans-pro !important;
        }
    }

    .title {
        font-size: 350%;
        font-weight: 600;
        text-align: center;
        color: $blue;
        margin-bottom: 48px;
    }

    .statement {
        font-size: 160%;
        line-height: 1.4;
        margin-top: 16px;

        a {
            color: $blue;
            font-weight: 600;
        }

        &.mb {
            margin-bottom: 16px;
        }
    }

    .secondary-title {
        font-size: 280%;
        font-weight: 600;
    }

    .help-statement {
        margin-top: 32px;
        padding: 48px 0;
        background-color: white;

        p {
            font-size: 160%;
            line-height: 1.6;
        }

        a {
            color: $blue;
            font-weight: 600;
        }
    }

    .login-footer {
        margin-top: 48px;
        text-align: center;

        .app-store-badges {
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            
            @include devices(sm) {
                display: block;
            }

            a {
                margin: 0 0 8px 0;
                cursor: pointer;

                img {
                    width: 200px;
                    height: 59px;
                }

                &:last-child {
                    margin-right: 0;
                }

                @include devices(sm) {
                    margin: 0 24px 0 0;
                }
            }
        }

        .footer-links {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 16px;
            font-size: 160%;
            
            a {
                color: $grey;

                &:hover {
                    text-decoration: underline;
                }
            }

            span {
                margin: 0 12px;
                width: 8px;
                height: 0.6px;
                background-color: $grey;
            }
        }

        .copyright {
            font-size: 160%;
        }
    }

    .login-info-circle {
        border: 3px solid $blue;
        border-radius: 50%;
        padding: 5px 9px 4px;

        i {
            font-size: 140%;
            color: $blue;
        }
    }
}

.sm-input {
    position: relative;

    .sm-input-append-icon {
        position: absolute;
        right: 0;
        font-size: 180%;
        
        i {
            margin-top: 25%;
            cursor: pointer;
            z-index: 4;
        }
    }

    .sm-input-prepend-icon {
        position: absolute;
        left: 0;
        font-size: 200%;
    }

    &.disabled {
        opacity: 0.7
    }
}

.sm-bullet-point {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0 18px;

    p {
        font-size: 160%;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.small {

        p {
            font-size: 100%; 
        }
    }

    .left {
        display: flex;
        align-items: center;

        .bullet-point {
            display: block;
            min-width: 13px;
            height: 13px;
            border: 3px solid $blue;
            border-radius: 100%;
            margin-right: 18px;

            &.dash {
                border-radius: 0;
                height: 1px;
                border: 1px solid $blue
            }
        }
    }
}

.sm-typography {
    
    &.error {
        text-align: center;
        font-size: 240%;
    }
}

.sm-accordion {

    .menu-item-container {
        margin-bottom: 0;

        .menu-item-content {
            padding-right: 16px;
        }

        .menu-item-arrow {

            i {
                transform: rotate(0deg);
                transform-origin: 50% 50%;
                transition: transform 0.3s ease-in-out;
            }
        }

        &.open {

            .menu-item-arrow {

                i {
                    transform: rotate(90deg);
                    transform-origin: 50% 50%;
                    transition: transform 0.35s ease-in-out;
                }
            }
        }
    }

    .accordion-item {
        overflow: hidden;
        transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
        height: auto;
        max-height: 9999px;

        &.collapsed {
            max-height: 0px;
            transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
        }

        .accordion-content {
            font-size: 180%;
            padding: 12px 16px 24px;
            line-height: 1.4;

            a {
                color: $blue;
                font-weight: 600;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}