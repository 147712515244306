.page-body {

    &.authed {

        &.dark-mode {
            background-color: $pageBodyDark !important;

            @import './generic';
            @import './transactions';
            @import './cards';
            @import './payments';
            @import './international';
            @import './more';
            @import './form';
            @import './modal';
        }
    }
}