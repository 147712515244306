.payments-container {

    .add-cash-steps {
        p {
            color: $dark-sub-text;
        }
    }
}

.manage-payee-container {

    .payee-switch-container {
                
        .switch {

            &.active {
                background-color: $dark-box-background;
            }
        }
    }
}

.uk-payee-item {
    background-color: $dark-box-background;

    .delete-button {

        i {
            color: white !important;
        }
    }

    & .uk-payee-account {
        p {
            color: $dark-sub-text;
        }

        span {
            color: $dark-sub-text;
        }
    }
}