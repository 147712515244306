h1 {
    &.section-title {
        font-size: 400%;
        font-weight: 600;
        text-align: center;
        margin-bottom: 48px;
        position: relative;

        span {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 60%;
            cursor: pointer;

            i {
                font-size: 80%;
                margin-right: 8px;
            }
        }

        .info {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            font-size: 60%;
        }
    }
}


.more-container {
    
    h1 {
        &.section-title {
            margin-bottom: 50px;
        }
    }
}


h3 {
    &.section-title {
        font-size: 300%;
        font-weight: 500;
        margin-bottom: 16px;
    }
}



