//header
header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: $blue;
  
  img {
    height: 25px;
  }
}

//loggedIn Header
.authed-header {
  display: flex;
  color: #fff;
  padding-bottom: 0;

  .item {

    &-logo {
      flex: 1;

      img {
        height: 25px;
      }
    }

    &-account {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;

      .data-check-loading, .data-check-error, .data-check-no-data {
        padding: 0 0 36px 0;
      }

      .nav-links {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1rem;

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          margin: 0 25px;
          position: relative;
          border-radius: 30px 30px 0 0;
          padding: 7px 12px 10px;

          .link-icon {
            text-decoration: none;
            z-index: 1;

            span {
              display: flex;
              align-items: center;
              flex-direction: column;
  
              img {
                width: 25px;
                height: 25px;
                margin-bottom: 10px;
              }
  
              p {
                color: #fff;
              }
            }

          }

          .curve {
            display: none;
            position: absolute;
            bottom: 0;
            width: 186px;
            height: 65px;
          }

          &.active {
            .curve {
              display: block;
            }
            
            span {
              
              p {
                color: $blue;
              }
            }
          }

          p {
            font-size: 120%;
            @include source-sans-pro-semi-bold;
            text-align: center;
          }
        }
      }
    }

    &-notifications {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }

  &.dark-mode {
    .link-icon {
      span {
        p{
          color: white!important;
        }
      }
    }

    .language-switcher {
      .option {
        background-color: $dark-box-background!important;
        color: $dark-sub-text;

        &:hover {
          transition: background-color 0.1s ease-in!important;
          background-color: lighten($grey, 10%)!important;
      }
      }
    }
  }
}