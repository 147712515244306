.modal-block {
    background-color: $pageBodyDark;

    .generic-input {
        background-color: $dark-box-background;
    }   

    p {
        color: $dark-sub-text;
    }
}