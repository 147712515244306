.transferwise-container {

    .info-slug {
        color: $dark-sub-text !important;
    }

    .terms-links-wrapper {
    
        p {
            color: $dark-sub-text;
        }
    
        & .button {
            color: $blue;
        }
    }
}

.international-quote-calculator-container{
        
    .amount-input-container {
        background-color: $dark-box-background;

        input, div {
            color: white;
        }
    }

    .currency-code {
        color: $dark-sub-text;
    }

    .information-container {
        
        .information-fee, .information-rate {
            background-color: $dark-box-background;
            color: white;
        }
    }

    .arrival-time {
        color: $dark-sub-text;
    }
}