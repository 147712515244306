.password-strength-meter {
    text-align: left;
  }
  
  .password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    margin-bottom: 0.4rem;
  
    &::-webkit-progress-bar {
      background-color: #eee;
      border-radius: 3px;
    }
  
    &::-webkit-progress-bar {
      border-radius: 2px;
      background-size: 35px 20px, 100% 100%, 100% 100%;
    } 
    &::-moz-progress-bar {
      background-color: #eee;
      border-radius: 3px;
    }
  
    &::-moz-progress-bar {
      border-radius: 2px;
      background-size: 35px 20px, 100% 100%, 100% 100%;
    } 
  }
  
  .password-strength-meter-label {
    font-size: 0.6rem;
    text-align: left;
  }
  
  .strength-Weak {
    &::-webkit-progress-value, &::-moz-progress-bar{
      background-color: $meter-weak;
    }
  }
  .strength-Fair {
    &::-webkit-progress-value, &::-moz-progress-bar {
      background-color: $meter-fair;
    }
  }
  .strength-Good {
    &::-webkit-progress-value, &::-moz-progress-bar {
      background-color: $meter-good;
    }
  }
  .strength-Strong {
    &::-webkit-progress-value, &::-moz-progress-bar {
      background-color: $meter-strong;
    }
  }