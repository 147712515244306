.autocomplete-dropdown-container {

    .suggestion-item {
        color: white;
    }

    .auto-complete-icon {
        color: $dark-sub-text;
    }
}

.form-field {

    label {
        color: white
    }

    input {
        background-color: $dark-box-background;
        color: white;
        border: solid 1px #a8adb7;
    }
    
    & .generic-input {
        background-color: $dark-box-background !important;
        color: white;
        padding: 8px 12px;

        &.select-list {
            padding: 0;
        }

        & > .selected-box {

            & > .placeholder {
                color: white;
                padding-left: 8px;
            }

            i {
                padding-right: 8px;
            }
        }

        & > .option-selection {
            .option {
                background: $dark-box-background!important;
            }
        }
        
        &::placeholder {
            color: $dark-sub-text;
        }
        &::-webkit-input-placeholder {
            color: $dark-sub-text;
        }
    }
}

.switch-content {
    color: $dark-sub-text;
}

.sm-input {

    &.postcode {
        
        .location-search-input {
            padding-left: 42px !important;
        }

        .sm-input-prepend-icon {
            top: 50%;
            transform: translateY(-75%);
            padding-left: 12px;
        }
    }
}