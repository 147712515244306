
.adverts-container {
    max-width: 840px;
    margin: 0 auto;
  
    .adverts-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        position: relative;

        .item {
            width: 100%;
            height: 140px;
            border-radius: 20px; 

        }

        .adverts-arrow {
            background-color: $blue;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            @include vertical-center;
    
            .caret {
                margin-top: 5px;
                font-size: 360%;
                color: white;
            }
    
            &.left {
                left: -70px;
    
                .caret {
                    margin-left: -5px;
                }
            }
    
            &.right {
                right: -70px;
    
                .caret {
                    margin-right: -5px;
                }
            }
    
            &.disabled {
                background-color: lighten($blue, 30%);
            }
        }
    } 

    @media only screen and (max-width: 960px) {
        .adverts-grid {
            & > .item {
                height: 100px;
            }
        }
    }
}